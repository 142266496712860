import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Header, Form, Button, Message } from 'semantic-ui-react';
import { navigate } from 'gatsby';
import useForm from 'react-hook-form';
import AuthContext from '../../Context/AuthContext';
import handleErrors from '../../HandleErrors';
import AccountService from '../../../services/account';

const AccountForm = () => {
  const [apiError, setApiError] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [user, setUser] = useState({})
  const [success, setSuccess] = useState(false);
  const { token } = useContext(AuthContext);

  const { register, handleSubmit, errors, reset } = useForm();

  const fetchAccount = useCallback(async () => {
    await AccountService.me().then(data => {
      let { client } = data.data;
      let { name, document, email, phone } = client.data;

      reset({ name, document, email, phone });
    });
  }, [reset]);

  useEffect(() => {
    if (!token) {
      navigate('/login/');
    }

    fetchAccount();
  }, [token, fetchAccount]);

  const onSubmit = async (data, e) => {
    setLoading(true);

    await AccountService.updateAccount(data).then(
      () => {
        setLoading(false);
        setApiError([]);

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      },
      e => {
        setApiError(e.response.data.errors || e);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Header as="h3">Alterar Dados de Contato</Header>

      {apiError.length !== 0 ? handleErrors(apiError) : null}
      {success ? <Message positive>Items salvo com sucesso!</Message> : null}

      <Form
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        error={errors.length !== 0 || Object.entries(errors).length !== 0}
      >
        <Form.Field>
          <label>Nome</label>
          <input type="text" name="name" ref={register} />
        </Form.Field>

        <Form.Field>
          <label>CPF/CNPJ</label>
          <input type="text" name="document" ref={register} />
        </Form.Field>

        <Form.Field>
          <label>Telefone</label>
          <input type="text" name="phone" ref={register} />
        </Form.Field>

        <Form.Field>
          <label>E-mail</label>
          <input type="text" name="email" ref={register} />
        </Form.Field>

        <Button type="submit" color="orange">
          Alterar Dados
        </Button>
      </Form>
    </>
  );
};

export default AccountForm;

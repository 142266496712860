import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import SEO from '../components/SEO';
import { Header, Divider } from 'semantic-ui-react';
import Layout from '../components/Layout';
import AuthContext from '../components/Context/AuthContext';
import AccountService from '../services/account';
import Account from '../components/Account';

const MyAccount = ({ location }) => {
  const [user, setUser] = useState({});
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (!token) {
      navigate('/login/');
    }

    AccountService.me().then(data => {
      setUser(data.data);
    });
  }, [token]);

  return (
    <Layout location={location}>
      <SEO title="Minha Conta" />

      <Header as="h3">Olá {user.name}!</Header>

      <Divider />

      <Account />
    </Layout>
  );
};
export default MyAccount;

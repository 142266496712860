import React, { useState, useEffect, useCallback } from 'react';
import _map from 'lodash/map';
import {
  Card,
  Grid,
  Button,
  Message,
  Divider,
  Header,
  Label,
} from 'semantic-ui-react';
import Account from '../../../services/account';
import ButtonRemoveAddress from './ButtonRemoveAddress';
import ButtonModalForm from './ButtonModalFormEdit';
import ButtonModalFormCreate from './ButtonModalFormCreate';

const EmptyAddress = ({ onUpdate }) => {
  return (
    <>
      <Message
        // warning
        icon="warning circle"
        header="Ops!!! Você não cadastrou nenhum endereço para entrega."
        content="Clique aqui para cadastrar seu novo endereço."
      />
      <ButtonModalFormCreate resource={resourceDefault} onClose={onUpdate} />
    </>
  );
};

const CardHeader = ({ id, contact_name, resource, onUpdate }) => {
  const onClose = () => {
    onUpdate();
  };

  return (
    <Card.Header>
      <Grid>
        <Grid.Column floated="left" width={8}>
          {contact_name}
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          <Button.Group size="mini" icon>
            <ButtonModalForm resource={resource} onClose={onClose} />

            <ButtonRemoveAddress id={id} onClose={onClose} />
          </Button.Group>
        </Grid.Column>
      </Grid>
    </Card.Header>
  );
};

const Address = ({ onUpdate }) => {
  const [addresses, setAddresses] = useState([]);

  const fetchData = useCallback(async () => {
    let items = [];
    const data = await Account.addresses();

    items = _map(data.addresses, r => ({
      key: r.id,
      header: () => {
        if (r.is_default) {
          return (
            <>
              <Label attached="bottom right" style={{ zIndex: '1' }}>
                Padrão
              </Label>

              <CardHeader
                contact_name={r.contact_name}
                id={r.id}
                resource={r}
                onUpdate={onUpdate}
              />
            </>
          );
        }

        return (
          <CardHeader
            contact_name={r.contact_name}
            id={r.id}
            resource={r}
            onUpdate={onUpdate}
          />
        );
      },
      meta: () => {
        if (r.contact_position) {
          return <Card.Meta>{r.contact_position}</Card.Meta>;
        }
      },
      description: (
        <>
          {r.street}, {r.number}{' '}
          {r.additional_info ? `- ${r.additional_info}` : ''}
          {r.city_block} {r.city}/{r.state} <br />
          {r.zip_code}
        </>
      ),
    }));

    setAddresses(items);
  }, [onUpdate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!addresses.length) {
    return <EmptyAddress onUpdate={onUpdate} />;
  }

  return (
    <>
      <Header as="h2" textAlign="left">
        Minha Conta
      </Header>

      <Divider />

      <Card.Group items={addresses} />

      <Divider />

      <ButtonModalFormCreate resource={resourceDefault} onClose={onUpdate} />
    </>
  );
};

export default Address;

const resourceDefault = {
  id: 0,
  contact_name: '',
  contact_position: null,
  zip_code: '',
  street: '',
  number: '',
  city_block: '',
  city: '',
  state: '',
  additional_info: null,
  country: 'Brasil',
  is_default: false,
};

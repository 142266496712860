import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import Account from '../../../services/account';

const ButtonRemoveAddress = ({ id, onClose }) => {
  const destroy = async id => {
    await Account.addressesDestroy(id);
    onClose();
  };

  return (
    <Popup
      trigger={
        <Button title="Excluir Endereço">
          <Icon name="delete" />
        </Button>
      }
      on="click"
      pinned
    >
      <Button color="red" onClick={() => destroy(id)}>
        Apagar
      </Button>
    </Popup>
  );
};

export default ButtonRemoveAddress;

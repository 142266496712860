import React, { useState } from 'react';
import { Header, Form, Button, Message } from 'semantic-ui-react';
import Account from '../../../services/account';
import handleErrors from '../../HandleErrors';
import useForm from 'react-hook-form';

const PasswordForm = () => {
  const [apiError, setApiError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, e) => {
    setLoading(true);

    await Account.updatePassword(data).then(
      () => {
        setLoading(false);
        setApiError([]);
        e.target.reset();

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      },
      e => {
        setApiError(e.response.data.errors || e);
        setLoading(false);
      }
    );
  };

  const { register, handleSubmit, errors } = useForm();

  return (
    <>
      <Header as="h3">Alterar Senha</Header>

      {apiError.length !== 0 ? handleErrors(apiError) : null}
      {success ? <Message positive>Items salvo com sucesso!</Message> : null}

      <Form
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        error={apiError.length !== 0 || Object.entries(errors).length !== 0}
      >
        <Form.Field>
          <label>Senha</label>
          <input type="password" name="password" ref={register} />
        </Form.Field>

        <Form.Field>
          <label>Confirmar Senha</label>
          <input type="password" name="password_confirmation" ref={register} />
        </Form.Field>

        <Button type="submit" color="orange">
          Alterar Senha
        </Button>
      </Form>
    </>
  );
};

export default PasswordForm;

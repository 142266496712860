import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import Address from './Address';
import MyAccount from './MyAccount';

const Account = () => {
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActive] = useState(0);

  function openLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }

  const onUpdateAddress = () => {
    openLoading();
    setActive(null);
    setActive(1);
  };

  const panes = [
    {
      menuItem: 'Meus Pedidos',
      render: () => <Tab.Pane loading={loading}>Meus Pedidos</Tab.Pane>,
    },
    {
      menuItem: 'Endereços',
      render: () => (
        <Tab.Pane loading={loading}>
          <Address onUpdate={onUpdateAddress} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Editar Dados',
      render: () => (
        <Tab.Pane loading={loading}>
          <MyAccount />
        </Tab.Pane>
      ),
    },
  ];

  const onTabChange = (e, { activeIndex }) => {
    setActive(activeIndex);

    openLoading();
  };

  return (
    <Tab
      menu={{ attached: false, tabular: false }}
      panes={panes}
      onTabChange={onTabChange}
      activeIndex={activeIndex}
    />
  );
};
export default Account;

import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import Account from '../../../services/account';
import AddressFormModal from './AddressFormModal';

const ButtonModalForm = ({ resource, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState([]);

  const onSubmit = async data => {
    setLoading(true);

    await Account.addressesCreate(data).then(
      () => {
        setOpen(false);
        setLoading(false);
        onClose();
      },
      e => {
        setApiError(e.response.data.errors || e);
        setLoading(false);
      }
    );
  };

  const closeModal = () => setOpen(false);

  return (
    <>
      <Button
        title="Novo Endereço"
        onClick={() => setOpen(true)}
        color="orange"
      >
        Novo Endereço
      </Button>

      <AddressFormModal
        onClose={closeModal}
        onSubmit={onSubmit}
        apiError={apiError}
        open={open}
        loading={loading}
      />
    </>
  );
};

export default ButtonModalForm;

import React from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import AddressForm from './AddressForm';

const AddressFormModal = ({
  resource = {
    id: 0,
  },
  loading = false,
  open,
  onClose,
  onSubmit,
  apiError = [],
}) => {
  let title = 'Novo Endereço';
  if (resource.id) {
    title = 'Editar Endereço';
  }

  return (
    <Modal closeOnDimmerClick open={open}>
      <Icon name="close" onClick={() => onClose()} />

      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AddressForm
            apiError={apiError}
            onSubmit={onSubmit}
            loading={loading}
            resource={resource}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default AddressFormModal;

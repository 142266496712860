import React from 'react';
import { Grid, Header, Divider } from 'semantic-ui-react';
import PasswordForm from './PasswordForm';
import AccountForm from './AccountForm';

const MyAccount = () => {
  return (
    <>
      <Header as="h2">Minha Conta</Header>

      <Divider />

      <Grid stackable columns={2}>
        <Grid.Column>
          <AccountForm />
        </Grid.Column>
        <Grid.Column>
          <PasswordForm />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default MyAccount;
